import React, { ReactNode, Fragment, useState, useEffect } from "react";
import { HeadFC, Script } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-topbar-phone-button";
import ClientAreaButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/client-area-button";
import PhoneButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/popup-button";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    GA4,
    facebook,
    instagram,
    landingSubtitle,
    plantao,
  } = queryResult();

  const renderButtons = (trackEvent: any) => (
    <PhoneButton
      label="Entre em Contato"
      link="tel:+551150265568"
      clickEventName="click_ligar"
      containerLabel="pop_up_home"
      onClick={() => trackEvent({ action: "click_ligar" })}
      pulse
      
    ></PhoneButton>
  );

  const [pageLabel, setPageLabel] = useState("unknown");

  useEffect(() => {
    let path = window.location.pathname;
    if (path == "/") setPageLabel("home");
    else setPageLabel(window.location.pathname);
  }, []);

  return (
    <Fragment>
      {pageLabel === "/veterinario-em-sao-paulo" ? (
        <>{children}</>
      ) : (
        <LayoutLibrary
          renderButton24h={renderButtons}
          logoTopbar={getGatsbyImageNode(
            logoTopbar,
            `Logo ${companyName}`,
            true,
            false,
            false
          )}
          logoFooter={getGatsbyImageNode(
            logoFooter,
            `Logo ${companyName}`,
            true,
            true,
            true
          )}
          // renderButton24h={renderButtons}
          phone={"551150265568"}
          companyName={companyName}
          city={city}
          slogan={landingSubtitle}
          badge={{ type: "vet" }}
          facebook={facebook}
          instagram={instagram.user}
          linkedin="in/animal-pet-b771ab2b2"
          navItems={[
            {
              label: "Home",
              path: "/",
            },
            {
              label: "Fotos",
              path: "/galeria",
            },
            {
              label: "Fale Conosco",
              path: "/contato",
            },
          ]}
        >
          {children}
        </LayoutLibrary>
      )}
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
